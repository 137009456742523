.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* .carousel {
  width:100%;
  height:50px;
  margin: auto;
  font-weight: bold;
} */

@media (max-width: 900px) {
  .carousel {
    width:auto;
    height:auto;
  }
}

.datenschutz_text-p {
  font-family: Calibri, Arial, Helvetica;
  font-size: 0.90em;
}

.about_us_text-p {
  font-family: Calibri, Arial, Helvetica;
  font-weight: bold;
  font-size: 1.25em;
}

.about_us_text-h {
  font-family: Calibri, Arial, Helvetica;
  font-weight: bold;
}

.ant-table.ant-table-small {
  font-size: smaller;
}

.teaTypeButtonStyle {
  margin: 2px;
  padding: 2px;
  border-color: darkgrey;
}

.carousel-caption {
  top: 0;
  bottom: auto;
}

.carousel-caption h2 {
  background-color: rgb(0,0,0,0.75);
  text-shadow: 0 2px 0 black;
  color:white;
}

.btn-success {
  background-color:rgb(57, 181, 74);
}